// import React, { useEffect, useState } from 'react';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { useParams, useNavigate } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import auth from '../../firebase.init';
// import HeaderBottom from '../../components/HomePage/HeaderBottom';
// import toast from 'react-hot-toast';

// const CheckoutForm = ({ price, handleNewOrder }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [processing, setProcessing] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     setProcessing(true);

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//     });

//     if (error) {
//       setError(error.message);
//       setProcessing(false);
//     } else {
//       console.log('[PaymentMethod]', paymentMethod);

//       // Payment is successful, now create the order
//       handleNewOrder();

//       setProcessing(false);
//       toast.success('Payment Successful!');
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} className="card p-4 shadow-sm">
//       <div className="form-group mb-4">
//         <label htmlFor="card-element" className="form-label">Credit or debit card</label>
//         <div className="border rounded p-3">
//           <CardElement id="card-element" className="form-control" />
//         </div>
//       </div>
//       <button
//         type="submit"
//         disabled={!stripe || processing}
//         className="btn btn-primary w-100"
//       >
//         {processing ? 'Processing...' : 'Pay Now with Stripe'}
//       </button>
//       {error && <div className="alert alert-danger mt-3">{error}</div>}
//     </form>
//   );
// };

// const PayNow = () => {
//   const [user] = useAuthState(auth);
//   const { id } = useParams();
//   const [prices, setPrice] = useState({});
//   const [stripeKey, setStripeKey] = useState('');
//   const [keyError, setKeyError] = useState(''); // State for error messages
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Fetch Stripe key from the API
//     fetch('https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/stripe-keys')
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.length > 0) {
//           setStripeKey(data[0].stripeKey);
//         } else {
//           setKeyError('Stripe key not found. Please contact support.');
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching Stripe key:', error);
//         setKeyError('Failed to load Stripe key. Please try again later or contact support.');
//       });
//   }, []);

//   useEffect(() => {
//     fetch(`https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/pricing/${id}`)
//       .then((res) => res.json())
//       .then((info) => setPrice(info));
//   }, [id]);

//   const handleNewOrder = () => {
//     const packagePrice = prices.packagePrice;
//     const packageName = prices.packageName;
//     const customerEmail = user ? user.email : ''; // Use user email if logged in, else empty string

//     const paymentStatus = 'Paid';
//     const orderStatus = 'Pending';
//     const currentDate = new Date().toLocaleDateString('en-US', {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     });

//     const order = {
//       packageName,
//       packagePrice,
//       customerEmail, // Use the email if available, else it will be an empty string
//       paymentStatus,
//       orderStatus,
//       paymentDate: currentDate,
//     };

//     fetch('https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/new-order', {
//       method: 'POST',
//       headers: {
//         'content-type': 'application/json',
//       },
//       body: JSON.stringify(order),
//     })
//       .then((res) => res.json())
//       .then((result) => {
//         // Check if the result contains the order ID
//         if (result.orderId) {
//           window.scrollTo({
//             top: 0,
//             behavior: 'smooth', // Smooth scroll to top
//           });
//           navigate(`/order-details/${result.orderId}`);
//         } else {
//           console.error('Order ID not returned from server');
//         }
//       })
//       .catch((error) => {
//         console.error('Error creating order:', error);
//       });
//   };


//   // Load Stripe only if the key is fetched
//   const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

//   return (
//     <div>
//       <HeaderBottom />
//       <div className="d-flex justify-content-center align-items-center min-vh-100">
//         <div className="container">
//           {keyError ? ( // Show error message if keyError is present
//             <div className="alert alert-danger text-center">
//               {keyError}
//             </div>
//           ) : (
//             <div className="row justify-content-center">
//               <div className="col-lg-8 col-md-10">
//                 <div className="card shadow-lg">
//                   <div className="card-body">
//                     <h4 className="card-title text-center mb-4">Pay for {prices.packageName} Package</h4>
//                     <h5 className="card-title text-center mb-4">Price ${prices.packagePrice} USD</h5>
//                     {stripePromise && (
//                       <Elements stripe={stripePromise}>
//                         <CheckoutForm price={prices.packagePrice} handleNewOrder={handleNewOrder} />
//                       </Elements>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PayNow;

import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useParams, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import auth from '../../firebase.init';
import HeaderBottom from '../../components/HomePage/HeaderBottom';
import toast from 'react-hot-toast';

const CheckoutForm = ({ price, handleNewOrder, customerEmail }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      // Payment is successful, now create the order
      handleNewOrder(customerEmail); // Pass the customerEmail to handleNewOrder

      setProcessing(false);
      toast.success('Payment Successful!');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="card p-4 shadow-sm">
      <div className="form-group mb-4">
        <label htmlFor="card-element" className="form-label">Credit or debit card</label>
        <div className="border rounded p-3">
          <CardElement id="card-element" className="form-control" />
        </div>
      </div>
      <button
        type="submit"
        disabled={!stripe || processing}
        className="btn btn-primary w-100"
      >
        {processing ? 'Processing...' : 'Pay Now with Stripe'}
      </button>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </form>
  );
};

const PayNow = () => {
  const [user] = useAuthState(auth);
  const { id } = useParams();
  const [prices, setPrice] = useState({});
  const [stripeKey, setStripeKey] = useState('');
  const [keyError, setKeyError] = useState(''); // State for error messages
  const navigate = useNavigate();
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    // Fetch Stripe key from the API
    fetch('https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/stripe-keys')
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          setStripeKey(data[0].stripeKey);
        } else {
          setKeyError('Stripe key not found. Please contact support.');
        }
      })
      .catch((error) => {
        console.error('Error fetching Stripe key:', error);
        setKeyError('Failed to load Stripe key. Please try again later or contact support.');
      });
  }, []);

  useEffect(() => {
    fetch(`https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/pricing/${id}`)
      .then((res) => res.json())
      .then((info) => setPrice(info));
  }, [id]);

  const handleNewOrder = (email) => {
    const packagePrice = prices.packagePrice;
    const packageName = prices.packageName;
    const paymentStatus = 'Paid';
    const orderStatus = 'Pending';
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const order = {
      packageName,
      packagePrice,
      customerEmail: email || '', // Use the provided email or an empty string
      paymentStatus,
      orderStatus,
      paymentDate: currentDate,
    };

    fetch('https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/new-order', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(order),
    })
      .then((res) => res.json())
      .then((result) => {
        // Check if the result contains the order ID
        if (result.orderId) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scroll to top
          });
          navigate(`/order-details/${result.orderId}`);
        } else {
          console.error('Order ID not returned from server');
        }
      })
      .catch((error) => {
        console.error('Error creating order:', error);
      });
  };

  // Load Stripe only if the key is fetched
  const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

  return (
    <div>
      <HeaderBottom />
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="container">
          {keyError ? ( // Show error message if keyError is present
            <div className="alert alert-danger text-center">
              {keyError}
            </div>
          ) : (
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <h4 className="card-title text-center mb-4">Pay for {prices.packageName} Package</h4>
                    <h5 className="card-title text-center mb-4">Price ${prices.packagePrice} USD</h5>
                    {/* Conditionally render the customer email input */}
                    {!user && (
                      <div className="form-group mb-4">
                        <label htmlFor="customerEmail" className="form-label">Customer Email</label>
                        <input
                          type="text"
                          className="form-control"
                          name="customerEmail"
                          required
                          value={customerEmail}
                          onChange={(e) => setCustomerEmail(e.target.value)}
                        />
                      </div>
                    )}
                    {stripePromise && (
                      <Elements stripe={stripePromise}>
                        <CheckoutForm price={prices.packagePrice} handleNewOrder={handleNewOrder} customerEmail={user ? user.email : customerEmail} />
                      </Elements>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayNow;
