import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';


const OrderDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [order, setOrder] = useState({});

    useEffect(() => {
        fetch(`https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/order/${id}`)
            .then((res) => res.json())
            .then((info) => setOrder(info));
    }, [id]);

    const handleEdit = (event) => {
        event.preventDefault();

        const customerEmail = event.target.customerEmail.value;
        const customerName = event.target.customerName.value;
        const customerAddress = event.target.customerAddress.value;
        const customerCountry = event.target.customerCountry.value;

        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        const updatedOrder = {
            customerEmail,
            customerName,
            customerAddress,
            customerCountry,
            orderDate: currentDate,
        };

        // Perform a PUT request to update the existing order with the ID
        fetch(`https://environmentalseoclone10-aa709c1f7ee6.herokuapp.com/order-customer-data/${id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(updatedOrder),
        })
            .then((res) => res.json())
            .then((result) => {
                navigate('/my-order');  // Redirect after successful update
            });
    };

    return (
        <>
            <HeaderBottom />
            <section id="services" className="services-area pt-120 pb-90 fix">
                <div className="container">
                    <div className="row">
                        <h2>Complete Your Order</h2>
                        <form className="contact-form mt-5" onSubmit={handleEdit}>
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="packageName" value={order.packageName} readOnly />
                                    </div>
                                </div>
                             
                                <div className="col-lg-8 col-md-8">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="packagePrice" value={order.packagePrice} readOnly />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="form-group">
                                        <input type="email" value={order.customerEmail} name="customerEmail" className="form-control" readOnly />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="form-group">
                                        <input type="text" name="customerName" className="form-control" placeholder="Full Name" required />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="form-group">
                                        <input type="text" name="customerAddress" className="form-control" placeholder="Your Address" required />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="form-group">
                                        <input type="text" name="customerCountry" className="form-control" placeholder="Your Country" required />
                                    </div>
                                </div>
                                <div className="slider-btn">
                                    <button className="theme-btn" data-animation="fadeInRight" data-delay=".8s">Place Order Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OrderDetails;